<div
  class="relative bg-cover bg-center bg-no-repeat h-screen background-image-container"
>
  <div><img src="/assets/images/login-img.svg" class="bottom-image" /></div>

  <div
    class="absolute inset-0 bg-black bg-opacity-50 background-opacity-container"
  ></div>
  <div
    class="main-dashboard-container container mx-auto h-full flex items-center justify-center md:justify-between"
  >
    <div class="flex dashboard-content p-16">
      <form
        class="select-btn-container"
        [formGroup]="formGroup"
        *ngIf="!isToggleSearch"
      >
        <p-selectButton
          [options]="stateOptions"
          formControlName="selectBtnValue"
          optionLabel="label"
          optionValue="value"
          [allowEmpty]="false"
        >
          <ng-template let-option pTemplate="item">
            <img
              [src]="getIconSource(option)"
              class="selectbutton-icon"
              alt="{{ option.label | translate }} icon"
            />
            {{ option.label | translate }}
          </ng-template>
        </p-selectButton>
      </form>
      <div class="flex justify-content-end gap-3" *ngIf="!isToggleSearch">
        <app-search-input
          *ngIf="currentView === 'listView' && !isMobileView"
          (searchChange)="onSearch($event)"
          placeholder="Search by Plant Name"
          [searchValue]="searchValue"
        ></app-search-input>
        <img
          src="assets/images/search-img.svg"
          *ngIf="currentView === 'listView' && isMobileView"
          (click)="toggleSearch()"
        />
        <app-button
          *ngIf="roleId === userRoleId.ADMIN"
          [name]="''"
          [btnClass]="'add-plant-btn'"
          (onClick)="onAddPlant()"
        >
          <span class="add-plant-text-full"
            >+ {{ "ADD_PLANT" | translate }}</span
          >
          <span class="add-plant-text-mobile">+</span>
        </app-button>
      </div>
      <app-search-input
        class="w-full"
        *ngIf="isToggleSearch && isMobileView"
        placeholder="SEARCH"
        [searchValue]="searchValue"
        (searchChange)="onSearch($event)"
        (searchIconClickedEmit)="searchIconClickedEmit()"
      ></app-search-input>
    </div>
    <div
      *ngIf="currentView === 'listView'"
      class="dashboard-content table-content mt-4"
    >
      <app-table
        [tableData]="plantTableData"
        [headers]="headerData"
        [currentPage]="currentPage"
        [totalRecords]="totalRecords"
        [rows]="5"
        (pageChange)="onPageChange($event)"
        [actionItems]="
          this.roleId === this.userRoleId.ADMIN &&
          plantTableMenuItems.bind(this)
        "
        class="display-none"
        [tableClass]="'plant-table'"
      >
      </app-table>
      <app-plant-location-card
        [plantData]="plantData"
        [actionItems]="plantTableMenuItems.bind(this)"
        class="plant-location-list"
      >
      </app-plant-location-card>
    </div>
    <div
      *ngIf="currentView === 'mapView'"
      class="dashboard-content map-container mt-4"
    >
      <app-map class="map-content" [plantData]="plantData"></app-map>
    </div>
  </div>
</div>
