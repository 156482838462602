import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { Subscription } from 'rxjs';
import { API_END_POINTS } from '../../constants/constant';
import { PlantData } from '../../interfaces/plant.interface';
import { SelectButton } from '../../interfaces/select-button.interface';
import { HttpService } from '../../services/http.service';
import { ButtonComponent } from '../../shared/button/button.component';
import { MapComponent } from '../../shared/map/map.component';
import { PlantLocationCardComponent } from '../../shared/plant-location-card/plant-location-card.component';
import { SearchInputComponent } from '../../shared/search-input/search-input.component';
import { SortByComponent } from '../../shared/sort-by/sort-by.component';
import { TableComponent } from '../../shared/table/table.component';
import { AddPlantModalComponent } from '../modals/add-plant-modal/add-plant-modal.component';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { SuccessModalComponent } from '../modals/success-modal/success-modal.component';
import { USER_ROLE_ID } from '@constants/meta-data';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    SortByComponent,
    SelectButtonModule,
    FormsModule,
    SearchInputComponent,
    ButtonComponent,
    ReactiveFormsModule,
    TableComponent,
    MapComponent,
    CommonModule,
    PlantLocationCardComponent,
    TranslateModule,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit, OnDestroy {
  plantData!: PlantData[];
  plantTableData!: PlantData[];
  formGroup!: FormGroup;
  ref!: DynamicDialogRef;
  currentPage: number = 0;
  pageSize: number = 0;
  totalRecords: number = 0;
  searchValue: string = '';
  modalContentTxt: string = '';
  currentView: string = 'mapView';
  isMobileView: boolean = false;
  isToggleSearch: boolean = false;
  confirmSubscription?: Subscription;
  selectorBtnSubscription?: Subscription;
  readonly tabletBreakpoint: number = 600;
  roleId: any = null;
  userRoleId = USER_ROLE_ID;

  stateOptions: SelectButton[] = [
    {
      label: this.translateService.instant('MAP_VIEW'),
      value: 'mapView',
      icon: '/assets/images/map-view-img.svg',
      activeIcon: '/assets/images/active-map-view-img.svg',
    },
    {
      label: this.translateService.instant('LIST_VIEW'),
      value: 'listView',
      icon: '/assets/images/list-view-img.svg',
      activeIcon: '/assets/images/active-list-view-img.svg',
    },
  ];

  headerData = [
    { title: 'Name of Plants', key: 'plantName' },
    { title: 'Plant Address', key: 'address' },
    { title: 'State', key: 'stateName' },
    { title: 'Status', key: 'isActive' },
  ];

  plantTableMenuItems(plantData: PlantData): MenuItem[] {
    if (plantData.isActive === true) {
      return [
        {
          label: 'Edit',
          command: () => this.onEditPlant(plantData),
        },
        {
          label: 'Archive',
          command: () => this.onArchivePlant(plantData),
        },
      ];
    } else {
      return [
        {
          label: 'Restore',
          command: () => this.onRestorePlant(plantData),
        },
      ];
    }
  }

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private httpService: HttpService
  ) {
    this.checkScreenSize();
  }

  ngOnInit() {
    this.roleId = localStorage.getItem('userRoleId');
    this.formGroup = new FormGroup({
      selectBtnValue: new FormControl(this.currentView),
    });

    this.selectorBtnSubscription = this.formGroup
      .get('selectBtnValue')
      ?.valueChanges.subscribe((newValue) => {
        this.currentView = newValue;
        this.searchValue = '';
        if (this.currentView === 'mapView') {
          this.pageSize = 0;
          this.currentPage = 0;
          this.plantTableData = [];
        } else {
          this.pageSize = 5;
          this.currentPage = 1;
        }
        this.getPlantData();
      }) as Subscription;
    this.getPlantData();
  }

  onSearch(searchValue: string): void {
    if (this.isToggleSearch === true) {
      this.pageSize = 0;
      this.currentPage = 0;
    }
    this.searchValue = searchValue;
    this.pageSize = 5;
    this.currentPage = 1;
    this.getPlantData();
  }

  getPlantData() {
    const url = API_END_POINTS.GET_PLANT;
    let params = new HttpParams();
    if (this.searchValue) {
      params = params.set('Search', this.searchValue);
    }
    params = params.set('PageNumber', this.currentPage);
    params = params.set('PageSize', this.pageSize);
    this.httpService
      .getRequest(url, null, { params })
      .then((response) => {
        if (response) {
          if (this.currentView === 'mapView' || this.isToggleSearch === true) {
            this.plantData = response.data;
          } else {
            this.plantTableData = response.data;
            this.plantTableData.forEach((plantData) => {
              plantData['plantName'] = plantData['name'];
            });
            this.totalRecords = response.totalRecords;
          }
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }

  onPageChange(event: number) {
    this.currentPage = event;
    this.getPlantData();
  }

  restorePlantData(plantData: PlantData, modalSuccessContent: string) {
    const url = `${API_END_POINTS.RESTORE_PLANT}${plantData.id}/restore`;
    this.httpService
      .patchRequest(url, null)
      .then((response) => {
        if (response && modalSuccessContent) {
          this.getPlantData();
          this.ref = this.dialogService.open(SuccessModalComponent, {
            data: {
              headingContent: modalSuccessContent,
              contentName: plantData.name,
            },
            width: '384px',
            dismissableMask: true,
            styleClass: this.isMobileView
              ? 'custom-modal-border-radius mobile-modal'
              : 'custom-modal-border-radius',
          });
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }

  archivePlantData(plantData: PlantData, modalSuccessContent: string) {
    const url = `${API_END_POINTS.ARCHIVE_PLANT}${plantData.id}/archive`;
    this.httpService
      .patchRequest(url, null)
      .then((response) => {
        if (response && modalSuccessContent) {
          this.ref = this.dialogService.open(SuccessModalComponent, {
            data: {
              headingContent: modalSuccessContent,
              contentName: plantData.name,
            },
            width: '384px',
            dismissableMask: true,
            styleClass: this.isMobileView
              ? 'custom-modal-border-radius mobile-modal'
              : 'custom-modal-border-radius',
          });
          this.getPlantData();
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }

  onAddPlant() {
    this.ref = this.dialogService.open(AddPlantModalComponent, {
      data: { action: 'add' },
      width: '480px',
      styleClass: this.isMobileView
        ? 'custom-modal-border-radius mobile-modal'
        : 'custom-modal-border-radius',
    });
    this.ref.onClose.subscribe((result) => {
      if (result && result.action === 'add') {
        this.currentPage = 1;
        this.getPlantData();
      }
    });
  }

  onEditPlant(plantData: PlantData) {
    this.ref = this.dialogService.open(AddPlantModalComponent, {
      data: { plantData, action: 'edit' },
      width: '480px',
      styleClass: this.isMobileView
        ? 'custom-modal-border-radius mobile-modal'
        : 'custom-modal-border-radius',
    });
    this.ref.onClose.subscribe((result) => {
      if (result && result.action === 'edit') {
        this.getPlantData();
      }
    });
  }

  onArchivePlant(plantData: PlantData) {
    this.modalContentTxt = this.translateService.instant(
      'ARCHIVE_CONFIRMATION'
    );
    const ModalImageUrl = '/assets/images/archive-plant-img.svg';
    this.openModal(ModalImageUrl, plantData);
  }

  onRestorePlant(plantData: PlantData) {
    this.modalContentTxt = this.translateService.instant(
      'RESTORE_CONFIRMATION'
    );
    const ModalImageUrl = '/assets/images/restore-plant-img.svg';
    this.openModal(ModalImageUrl, plantData);
  }

  openModal(ModalImageUrl: string, plantData: PlantData): void {
    this.ref = this.dialogService.open(ConfirmationModalComponent, {
      data: {
        headingContent: this.modalContentTxt,
        imageUrl: ModalImageUrl,
        codeContent: plantData.name,
      },
      width: '384px',
      dismissableMask: true,
      styleClass: this.isMobileView
        ? 'custom-modal-border-radius mobile-modal'
        : 'custom-modal-border-radius',
    });
    this.confirmSubscription = this.ref.onClose.subscribe((result) => {
      if (result === 'confirmed') {
        this.onConfirmation(plantData);
      }
    });
  }

  onConfirmation(plantData: PlantData) {
    let modalSuccessContent = '';
    if (this.modalContentTxt === 'Are you sure you want to Archive') {
      modalSuccessContent = this.translateService.instant('ARCHIVE_SUCCESS');
      this.archivePlantData(plantData, modalSuccessContent);
    } else if ('Are you sure you want to Restore') {
      modalSuccessContent = this.translateService.instant('RESTORE_SUCCESS');
      this.restorePlantData(plantData, modalSuccessContent);
    }
  }

  getIconSource(option: SelectButton): string {
    return this.formGroup.value.selectBtnValue === option.value
      ? option.activeIcon
      : option.icon;
  }

  toggleSearch(): void {
    this.isToggleSearch = true;
  }

  searchIconClickedEmit() {
    if (this.isToggleSearch && this.isMobileView) {
      this.isToggleSearch = false;
    }
  }

  checkScreenSize() {
    this.isMobileView = window.innerWidth < 768;
    if (this.isMobileView) {
      this.isToggleSearch = false;
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.checkScreenSize();
  }

  ngOnDestroy(): void {
    if (this.confirmSubscription) {
      this.confirmSubscription?.unsubscribe();
    }
    if (this.selectorBtnSubscription)
      this.selectorBtnSubscription?.unsubscribe();
  }
}
